import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    Després de l'èxit aclaparador del nostre primer llibre, tornem amb una nova
                    edició actualitzada per a la convocatòria del 2024!
                </p>

                <p>
                    Més del 50% de les preguntes dels exàmens dels últims anys es trobaven als
                    nostres llibres. Aquest segon volum és el complement perfecte al primer i a la
                    nostra guia oficial.
                </p>

                <p>
                    Inclou 500 preguntes extretes directament del temari, seguint l'estructura del
                    mètode A: una pregunta, una resposta i ampliacions d'informació en les respostes
                    més rellevants.
                </p>

                <p>
                    Estem segurs que aquest llibre t'aproparà encara més al teu somni de ser policia
                    local!
                </p>

                <p>
                    I recorda, amb la compra del llibre, tindràs accés exclusiu i totalment gratuït
                    a un mòdul de pràctiques dins de l'app iMossos.
                </p>

                <p>Estàs preparat/da?</p>

                <div>
                    <strong>Com accedir al mòdul d'iMossos?</strong>
                    <br />

                    <ul className="mt-2">
                        <li>1. Descarrega l'aplicació.</li>
                        <li>
                            2. Inicia sessió amb el mateix correu que vas fer la compra del llibre.
                        </li>
                        <li>3. Dintre l'aplicació, trobaràs el mòdul actiu.</li>
                    </ul>
                </div>

                <div>
                    <strong>
                        Com accedir al mòdul d'iMossos, si ja estic registrat amb un altre correu?
                    </strong>
                    <br />
                    Contacta amb nosaltres a través de{" "}
                    <a
                        href="mailto:info@iopos.academy"
                        className="text-cpurple font-semibold hover:text-ccyan duration-300"
                    >
                        info@iopos.academy
                    </a>{" "}
                    i t'ajudarem a activar el teu mòdul.
                </div>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/179/document2.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    />
                </div>

                <p className="text-sm">
                    No pots visualitzar la prèvia? Fes clic{" "}
                    <Link
                        href={require("@static/documents/products/179/document2.pdf")}
                        target="_blank"
                        rel="nopener noreferrer"
                        className="text-cpurple font-semibold hover:text-ccyan duration-300"
                    >
                        aquí
                    </Link>
                    .
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Aconsegueix el teu somni amb aquest segon volum i accedeix al mòdul exclusiu de l'app iMossos."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
